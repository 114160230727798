import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import withLayout from '../../layout';
import { withAppContext } from '../../components/withAppContext';

import { Input } from '../../components/Form/Input';
import { apiUpdateProfile, apiFetchProfile, apiSafeSearchList } from '../../util/api';
import { getUser } from '../../util/auth';
import { registerTypes } from '../../components/Register';
import { IpInput } from '../../components/Form/IpInput';
import { PortalSettings } from '../../components/PortalSettings/portal-settings';
import { adminApiPortalList } from '../../util/adminApi';
import { AccessibilityMenu } from '../../components/AccessibilityMenu/AccessibilityMenu';

class SettingsPage extends Component {
  initialState = {
    type: registerTypes[0],
    ...this.props.appContext.user,
    password: '',
    confirmPassword: '',
    deletePassword: '',
    deleteLoading: false,
    deleteError: '',
    safeSearches: [],
    availableSafeSearches: [],
    portals: [],
  };

  state = {
    ...this.initialState,
  };

  async componentDidMount() {
    const newProfile = await apiFetchProfile(this.props.appContext.user.username, getUser().token);
    const availableSafeSearches = await apiSafeSearchList(getUser().token);
    const availablePortals = await adminApiPortalList();
    let safeSerachesArr = availableSafeSearches.map(({ controlLabel }) => controlLabel);
    this.safeSearches = safeSerachesArr;

    this.setState({
      ...newProfile,
      availablePortals: availablePortals,
      availableSafeSearches: safeSerachesArr.filter((value, index) => {
        if (Boolean(newProfile.portals) && Boolean(newProfile.portals.find(({ name }) => name.toLowerCase() === value.toLowerCase()))) {
          return false;
        }

        return safeSerachesArr.indexOf(value) === index;
      }).sort(),

    });
  }

  /**
   * @param {import('react').ChangeEvent<HTMLInputElement>} event
   */
  handleChange = event => {
    const { name, value } = event.target;

    if (name === 'safeSearches') {
      let safeSearches = [...this.state.safeSearches];

      if (!Array.isArray(safeSearches)) {
        safeSearches = [];
      }

      const index = safeSearches.indexOf(value);

      if (index > -1) {
        safeSearches.splice(index, 1);
      } else {
        safeSearches.push(value);
      }

      this.setState({ safeSearches: safeSearches });
    } else if (name === 'apiOnTheFly' || name === 'ageXml') {
      this.setState({ [name]: !this.state[name] });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  /**
   * @param {import('react').FormEvent} event
   */
  handleSubmit = async event => {
    event.preventDefault();

    const { password, confirmPassword, ...profile } = this.state;

    if (password.length > 4 && password === confirmPassword) {
      profile.password = password;
    }

    // TODO: check wheter the portals are sent to the API correctly
    // they should be on `profile.portals`
    const updatedUser = await apiUpdateProfile(getUser().token, profile);
    this.props.appContext.changeUserState(updatedUser);
    this.setState({ ...updatedUser, isEditing: false, newip: '' });
  };

  /**
   * @param {import('react').FormEvent} event
   */
  handleReset = event => {
    event.preventDefault();
    this.setState(() => this.initialState);
  };

  render() {
    const { newip, ips, availableSafeSearches, safeSearches } = this.state;
    const { formatMessage } = this.props.intl;

    const dynIps = ips.filter(({ dyndns }) => dyndns);
    const staticIps = ips.filter(({ dyndns }) => !dyndns);

    return (
      <>
      <AccessibilityMenu i18nKey="settings" />
        <form method="post" onSubmit={this.handleSubmit} onReset={this.handleReset}>
          <h4 className="form-label"><FormattedMessage id={'profile.ip_addresses'} /></h4>
          <div dangerouslySetInnerHTML={{ __html: `<p>${formatMessage({ id: 'profile.ip_addresses_info' })}</p>` }} />
          {Boolean(staticIps.length) &&
            staticIps.map((ip, index) => {
              return (
                <IpInput
                  key={ip.id}
                  ip={ip}
                  index={index}
                  handleChange={this.handleChange}
                  isEditing={true}
                />
              );
            })}
          <Input
            type="text"
            name={`newip`}
            label={formatMessage({ id: 'profile.ip_addresses_new' })}
            placeholder=""
            value={newip}
            handleChange={this.handleChange}
          />
          {Boolean(dynIps.length) && <label className="form-label"><FormattedMessage id="profile.ip_addresses_dynamic" /></label>}
          {Boolean(dynIps.length) &&
            dynIps.map((ip, index) => {
              return (
                <span key={ip.id}>
                  {ip.ip}
                  {index + 1 < dynIps.length ? ', ' : ''}
                </span>
              );
            })}
          <br />
          <h4><FormattedMessage id="profile.uses" /></h4>
          <div className="form-group">
            <label className="form-label"><input
              type="checkbox"
              defaultChecked={this.state.ageXml}
              name="ageXml"
              onChange={this.handleChange}
            /> <FormattedMessage id="settings.use_age_xml" /></label>
          </div>

          <div className="form-group">
            <label className="form-label"><input
              type="checkbox"
              defaultChecked={this.state.apiOnTheFly}
              name="apiOnTheFly"
              onChange={this.handleChange}
            /> <FormattedMessage id="settings.use_otf" /></label>
          </div>

          <br />
          {this.state.portals.length > 0 && <><br />
            <h4><FormattedMessage id="portals.portals" /></h4>
            {this.state.availablePortals.map((portal, i) => {
              return (
                <PortalSettings
                  key={portal.name}
                  name={portal.name}
                  active={this.state.portals && this.state.portals.find(({name}) => name === portal.name) && (this.state.portals.find(({name}) => name === portal.name)).active}
                  hasSafeSearch={portal.safeSearches.length > 0}
                  safeSearch={this.state.portals && this.state.portals.find(({name}) => name === portal.name) && (this.state.portals.find(({name}) => name === portal.name)).safeSearch}
                  text={portal.text}
                  onActiveToggle={() => {
                    const newPortals = [...this.state.portals];
                    const newPortal = { ...this.state.portals.find(({name}) => name === portal.name), active: !this.state.portals.find(({name}) => name === portal.name).active };
                    newPortals[i] = newPortal;
                    this.setState({ portals: newPortals });
                  }}
                  onSafeSearchToggle={() => {
                    const newPortals = [...this.state.portals];
                    const newPortal = { ...this.state.portals.find(({name}) => name === portal.name), safeSearch: !this.state.portals.find(({name}) => name === portal.name).safeSearch };
                    newPortals[i] = newPortal;
                    this.setState({ portals: newPortals });
                  }}
                />
              );
            })}</>}


          <button className="btn mr-2" type="reset">
            <FormattedMessage id="profile.reset" />
          </button>
          <button className="btn btn-primary ml-2" type="submit">
            <FormattedMessage id="profile.save" />
          </button>
        </form>
      </>
    );
  }
}

const customProps = {
  localeKey: 'dashboard',
  withAuth: true,
  title: <FormattedMessage id="profile.title" />,
};

export default withLayout(customProps)(withAppContext(injectIntl(SettingsPage)));
